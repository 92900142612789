import React from 'react'
import scrollImg from  '../../../img/categoriesScroll.png'

import './index.scss'

const Categories = () => {
  console.log("render Categories")
  return(
    <section className={'categories-section'}>
        <div className="categories-info-block">
          <h2 className={'categories-title'}>Explore infinite content </h2>
          <div className="categories-text">Our state-of-the-art neural networks allow us to generate an infinite number of tasks with images</div>
        </div>
        <div className="scroll-block">
          <img src={scrollImg} alt={""}/>
        </div>
      {/*<div className="ellipse"/>*/}
    </section>
  )
}

export default Categories