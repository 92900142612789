import React from 'react'

import Button from "../../General/Button";

import firstAchievement from '../../../img/achImage.png'
import secondAchievement from '../../../img/secondAchImage.png'
import thirdAchievement from '../../../img/ThirdAchImage.png'
import confetti from '../../../img/confetti.svg'
import topEllipse from '../../../img/elipseAchWhite.svg'


import './achievements.scss'


const Achievements = () => {
  console.log("render Achievements")
  return (
      <section className={'achievement-section'}>
        <img className={"ellipse"} src={topEllipse} alt={""}/>
        <div className="achievement-info-block">
          <h2 className={'achievement-title'}>Get achievements</h2>
          <div className="achievement-text">Learning process gets even more exciting when you gain achievements for various activities</div>
          <Button white />
        </div>
        <div className="achievement-photo-group">
          <img src={firstAchievement} alt="first" className={'first-achievement'}/>
          <img src={secondAchievement} alt="second" className={'second-achievement'}/>
          <img src={thirdAchievement} alt="third" className={'third-achievement'}/>
          <img src={confetti} alt="confetti" className={'confetti'}/>
        </div>
        {/*<div className="first-ellipse"/>*/}
      </section>
  )
}

export default Achievements