import React, {useState} from 'react'
import './Task.scss';
// import snowboard from "./img/snowboard.jpeg"
import logo from "../img/horisontal-merged.svg"

function Task() {

  const [correctAnswers, setCorrectAnswers] = useState(0)
  const [stage, setStage] = useState(0)

  const [isDownloadScreen, setIsDownloadScreen] = useState(false)
  const [isConvincing, setIsConvincing] = useState(false)

  const [captionTokens, setCaptionTokens] = useState(["A", "???", "and", "a", "???", "in", "a", "room"])
  const [tasks, setTasks] = useState([
    {
      "gap_index": 1,
      "gap_text": "lamp",
      "distractors": "candle|lamp|flare|pine tree",
      "answer": null
    },
    {
      "gap_index": 4,
      "gap_text": "chair",
      "distractors": "lounge|luggage|hammock|chair",
      "answer": null
    }
  ])

  const clickButton = (buttonText, task) => {
    setTimeout(() => {
      let newCaptionTokens = [...captionTokens]
      newCaptionTokens[tasks[stage].gap_index] = task.gap_text
      setCaptionTokens(newCaptionTokens)

      let newTasks = tasks.slice(0,)
      newTasks[stage].answer = buttonText
      setTasks(newTasks)

      setStage(stage + 1)

      if (task.gap_text === buttonText) {
        setCorrectAnswers(correctAnswers + 1)
      }

      if (stage === tasks.length-1){
        setTimeout(() => {
          setIsDownloadScreen(true)
        }, 1000)
      }
    }, 250)
  }

  const Button = (props) => {
    const {buttonNumber, task} = props
    const [isClicked, setIsClicked] = useState(false)
    const buttonText = task.distractors.split("|")[buttonNumber]
    return (
      <button
        className={
          `phone-button 
          ${buttonText === task.gap_text ? " answer" : ""} 
          ${isClicked ? " clicked" : ""}`
        }
        onClick={() => {
          setIsClicked(true)
          clickButton(buttonText, task)
        }}
      >
        {buttonText}
      </button>
    )
  }

  let textParts = []

  for (let i = 0; i < tasks.length; i++) {
    const isCorrect = tasks[i].answer === tasks[i].gap_text

    textParts.push(
      <span>
        {captionTokens.slice((i === 0 ? 0 : tasks[i - 1].gap_index + 1), tasks[i].gap_index).join(" ")}
      </span>
    )

    if (i < stage) {
      textParts.push(
        <span className={"pastGap" + (isCorrect ? " correct" : "")}>
          {tasks[i].gap_text}
        </span>
      )
    } else if (i === stage) {
      textParts.push(<span className={"currentGap"}>___</span>)
    } else if (i > stage) {
      textParts.push(<span className={"futureGap"}>___</span>)
    }

    if (i === (tasks.length - 1)) {
      textParts.push(<span>{captionTokens.slice(tasks[i].gap_index + 1,).join(" ")}</span>)
    }
  }

  const finalScore = correctAnswers / 2 * 100

  const task = tasks[stage] || tasks[0]

  return (
    <div className="Task">
      <div className={'phone'}>
        <div className={"bottom-panel" + (isDownloadScreen ? " final" : "")}>
          <div className={"emojiContainer" + (stage === tasks.length ? "" : " hidden")}>
            <div className={"score" + (isDownloadScreen ? " final" : "")}>
              {correctAnswers} / {tasks.length}
            </div>
            <div className={"score-message-title" + (isDownloadScreen ? " final" : "")}>
              {finalScore > 33 ? (finalScore > 66 ?
                "You are amazing 😎":
                "Almost there 😮"):
                "Dont worry 😌"}
            </div>
            <div className={"score-message-subtitle" + (isDownloadScreen ? " final" : "")}>
              {finalScore > 33 ? (finalScore > 66 ?
                "Ready for a real challenge?":
                "improve your skills!"):
                "practice makes perfect."}
            </div>
            <img src={logo} className={"image" + (isDownloadScreen ? " final" : "")}/>

            <a className={"appStoreButton" + (isDownloadScreen ? " final" : "")} href={"https://apps.apple.com/app/apple-store/id1546232698?pt=120099044&ct=landing&mt=8"}/>
          </div>
          <div className={"bottomContainer" + (stage !== tasks.length ? "" : " hidden")}>
          <div className={"text"}>
            {textParts}
          </div>
          <div className={"buttons"}>
            <div className={"horizontal"}>
              <Button buttonNumber={0} task={task}/>
              <Button buttonNumber={1} task={task}/>
            </div>
            <div className={"horizontal"}>
              <Button buttonNumber={2} task={task}/>
              <Button buttonNumber={3} task={task}/>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Task;
