import React from 'react'

import Header from "./components/Header";
import TopView from "./components/TopView";
import MainView from "./components/MainView";
import Footer from "./components/Footer";

import './App.css';

function App() {
  return (
    <div className="App">
      <Header/>
      <TopView/>
      <MainView/>
      <Footer/>
    </div>
  );
}

export default App;
