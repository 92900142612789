import React from 'react'

import GameMode from "./GameMode";
import Categories from "./Categories";
import Achievements from "./Achievements";
import Challenges from "./ Challenges";
import Tasks from "./Tasks";
import Profile from "./Profile";

import './mainView.scss'


const MainView = () => {
  return(
      <section className={'main-view'}>
        <GameMode/>
        <Categories/>
        <Achievements/>
        <Challenges/>
        <Tasks/>
        <Profile/>
      </section>
  )
}

export default MainView