import React from 'react'

import profilePhoto from '../../../img/profile-photo.png'

import './profile.scss'

const Profile = () => {
  console.log("render Profile")
  return(
      <section className={'profile-section'}>
        <div className="profile-info-block">
          <div className="overhead">And much more</div>
          <h2 className={'profile-title'}> Track and share your progress</h2>
          <div className={'profile-text'}>Monitor your progress and activity level and share the results with your friends</div>
        </div>
        <img src={profilePhoto} alt="profile" className={'profile-photo'}/>
      </section>
  )
}

export default Profile
