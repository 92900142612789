import React from 'react'

import iphoneSki from '../../../img/iphoneSki.png'
import iphoneTable from '../../../img/iphoneTable.png'
import numberOne from '../../../img/1.svg'
import numberTwo from '../../../img/2.svg'

import './gameMode.scss'

import Button from "../../General/Button";

const GameMode = () => {
  console.log("render GameMode")
  return(
      <section className={'game-mode'}>
        <div className="group-of-photos">
          <img src={iphoneSki} alt="iphoneSki" className={'iphone-ski'}/>
          <img src={iphoneTable} alt="iphoneTable" className={'iphone-table'}/>
          <img src={numberOne} alt="numberOne" className={'number-one'}/>
          <img src={numberTwo} alt="numberTwo" className={'number-two'}/>
        </div>
        <div className="info-block">
          <h2 className={'game-mode-title'}>Choose a game mode</h2>
          <div className={'game-mode-text'}> We provide different game modes to associate language with images using visual boxes or filling missing gaps in sentences</div>
          <Button/>
        </div>
        {/*<div className="first-ellipse"/>*/}
        {/*<div className="second-ellipse"/>*/}
      </section>
  )
}

export default GameMode