import React from 'react'

import Button from "../../General/Button";
import './tasks.scss'
import mobileBackground from '../../../img/teddy.png'


const Tasks = () => {
  console.log("render Tasks")
  return (
      <section className={'tasks-section'}>
        <div className={"image-container"}>
          <img src={mobileBackground} alt={""}/>
        </div>
        <div className={'tasks-info-block'}>
          <h2 className={'tasks-title'}>Solve tasks with what you see</h2>
          <div className={'tasks-text'}>Just take a photo, Our neural networks will generate a task based on your own content</div>
          <Button/>
        </div>
      </section>
  )
}

export default Tasks