import React from 'react'
import logo from '../../img/horisontal-merged.svg'
import './header.scss'

const Header = () => {
  console.log("render header")
  return(
      <header>
        <img src={logo} alt={'PickLang logo'} width={"142"}/>
      </header>
  )
}

export default Header