import React, {useState} from 'react'
import star from '../../img/star.png'
import letter from '../../img/letter.png'
import flash from '../../img/flash.png'
import arrow from '../../img/arrow.svg'
import './topView.scss'
import Button from "../General/Button";
import ellipse from "../../img/elipse-top.svg";
import tabBar from "../../img/tab-bar.svg"
import Task from "../Task";
import iphone from "../../img/iPhone12-big.svg"

const TopView = () => {

  console.log("render TopView")
  const [correctAnswers, setCorrectAnswers] = useState(0)
  const [stage, setStage] = useState("first")

  let buttons = {
    first: [
      {text: "candle", isAnswer: false},
      {text: "lamp", isAnswer: true},
      {text: "flare", isAnswer: false},
      {text: "pine tree", isAnswer: false},
    ],
    second: [
      {text: "lounge", isAnswer: false},
      {text: "luggage", isAnswer: false},
      {text: "hammock", isAnswer: false},
      {text: "chair", isAnswer: true},
    ],
  }

  let texts = {
    first: "A ____ and a ____ in a room",
    second: "A lamp and a ____ in a room",
    final: "A lamp and a chair in a room"
  }

  const clickButton = (button) => {
    setTimeout(()=> {
      if (stage === "first") {
        setStage("second")
      } else if (stage === "second") {
        setStage("final")
      }

      if (button.isAnswer) {
        setCorrectAnswers(correctAnswers + 1)
      }
    }, 1000)
  }

  return (
    <section className={'top-view'}>
      <div className={"background-gradient"}/>
      <div className={"background-black"}/>
      <div className="top-view-info-block">
        <h1 className={'main-title'}>The most natural way to learn language</h1>
        <div className={'main-text'}>We combine the latest AI technologies to learn English using what you
          see<br/><br/>
          Our state-of-the-art neural network will learn from your feedback to provide you the best English learning
          experience
        </div>
        <Button/>
      </div>
      <div className="top-view-photo-group">
        <img src={star} alt="star" className={'star'}/>
        <img src={letter} alt="letter" className={'letter'}/>
        <img src={flash} alt="flash" className={'flash'}/>
        <img src={arrow} alt="arrow" className={'arrow'}/>
        <div className={'phone-container'}>
          <img src={iphone} alt={"iphone"} className={"iphone"}/>
          <Task/>
          <img src={tabBar} className={"tab-bar"} alt={"tab bar"}/>
          <div className={"bottom-white"} />
        </div>
        <div className={'arrow-text'}>Try it live</div>
        <img src={ellipse} alt={'ellipse'} className={'ellipse'}/>
      </div>
    </section>
  )
}

export default React.memo(TopView)