import React from 'react'

import Button from "../General/Button";

import footerPicklang from '../../img/footer-picklang.svg'
import icon from '../../img/appStoreiOS.svg'
import ellipse from '../../img/ellipse2.svg'

import './footer.scss'

const Footer = () => {
  return(
      <footer className={'footer'}>
        <div className={'footer-group-photo'}>
          <img src={ellipse} alt="ellipse" className={'ellipse'}/>
          <img src={icon} alt="icon" className={'footer-icon'}/>
          <img src={footerPicklang} alt="picklang" className={'footer-picklang'}/>
          <div className="footer-text">Try the new way to learn language absolutely free</div>
          <Button/>
          <div className={"links-flex"}>
            <div className={'copyright'}>© PickLang 2021</div>
            <div>
            <a className={'copyright'} href={"https://picklang.ml/privacy_policy"}>
              Privacy policy
            </a>
            <a className={'copyright'} href={"https://picklang.ml/terms_of_use"}>
              Terms of use
            </a>
            </div>
          </div>
        </div>
      </footer>
  )
}

export default Footer