import React from 'react'

import firstIphone from '../../../img/challenges-first-iphone.png'
import secondIphone from '../../../img/challenges-second-iphone.png'
import thirdIphone from '../../../img/challenges-third-iphone.png'
import line from '../../../img/line.svg'
import ellipses from '../../../img/doubleellipse.svg'

import './challenges.scss'

const Challenges = () => {
  console.log("render Challenges")
  return(
      <section className={'challenges-section'}>
        <div className="challenges-info-block">
          <h2 className={'challenges-title'}>Solve exciting challenges</h2>
          <div className="challenges-text">Dive into a topic you like and learn new things about the world - new challenges are added everyday</div>
        </div>
        <div className="wrapper">
          <div className="challenges-photo-group">
            <img src={firstIphone} alt="iphone" className={'first-iphone'}/>
            <img src={secondIphone} alt="iphone" className={'second-iphone'}/>
            <img src={thirdIphone} alt="iphone" className={'third-iphone'}/>
            <img src={line} alt="line" className={'line'}/>
            <img src={line} alt="line" className={'line-2'}/>
          </div>
        </div>
        {/*<div className="first-ellipse"/>*/}
        {/*<div className="second-ellipse"/>*/}
      </section>
  )
}

export default Challenges